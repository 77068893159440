import type { TraceErrorObject } from 'owa-trace';
import type { CustomWaterfallRange, PerformanceDatapointType } from './DatapointTypes';

type CustomGQLIndexes = [
    CustomWaterfallRange, // GQL_LINK_QUERY
    CustomWaterfallRange, // GQL_LINK_RESULT
    CustomWaterfallRange, // GQL_BRIDGE_QUERY
    CustomWaterfallRange, // GQL_BRIDGE_RESULT
    CustomWaterfallRange // GQL_BRIDGE_ERROR
];
type CustomNetworkIndexes = [
    CustomWaterfallRange, // NETWORK_REQUEST_STARTING
    CustomWaterfallRange, // NETWORK_REQUEST_ENDED
    CustomWaterfallRange // DATA_SOURCE
];
export interface GQLWaterfallSettings {
    disableGQLTimings?: boolean;
    customGQLIndexes?: CustomGQLIndexes;
    customNetworkIndexes?: CustomNetworkIndexes;
    isEnabled?: boolean;
    discardIfDefined?: boolean;
}

export interface GQLWaterfallContext {
    perfDatapoint?: PerformanceDatapointType;
}

// The Response type can't be serialized to be passed back to the main thread
export type TraceErrorObjectWithoutResponse = Omit<TraceErrorObject, 'Response'>;

export enum GQLCheckpoints {
    IDB_QUERY_MARK = 'INDEXDB_QUERY', // query being sent to idb resolver
    IDB_RESULT_MARK = 'INDEXDB_RESULT', // result received from idb resolver
    IDB_FALLBACK_MARK = 'INDEXDB_FALLBACK',
}
