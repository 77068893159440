import { getLogicalRing, getNativeHostVersion } from 'owa-config';
import { getItem } from 'owa-local-storage';
import { stringify } from 'owa-querystring';
import { ThemeConstants } from 'owa-theme-shared';

const supportUrl: string = 'https://cjs.diagnostics.office.com/support/inappdiagnostics?';
const localeStorageKey: string = 'OwaLocale';

export function getSupportUrl(): string {
    const nativeHostVersion = getNativeHostVersion();
    const logicalRing = getLogicalRing() || 'Production';
    const queryParams = {
        AppName: nativeHostVersion ? 'MONARCH' : 'OWA',
        AudienceGroup: logicalRing,
        Locale: getLocale(),
        ProductVersion: nativeHostVersion ?? '',
        Scenario: 'CantBoot',
        ThemeId: getThemeId(),
        SchemaVersion: '2',
        'x-ms-routing-name': getRoutingName(logicalRing),
    };

    return supportUrl + stringify(queryParams);
}

function getRoutingName(logicalRing: string) {
    return logicalRing === 'Dogfood' ? 'Dogfood' : 'Production';
}

function getLocale() {
    const cachedLocale = getItem(window, localeStorageKey);
    if (!cachedLocale) {
        return window.navigator.language;
    }

    return cachedLocale;
}

function getThemeId() {
    var cachedNormalizedTheme = getItem(window, ThemeConstants.LOCAL_STORAGE_KEY);
    return cachedNormalizedTheme && cachedNormalizedTheme.endsWith('dark') ? '4' : '0';
}
