import getUserAgentString from './getUserAgentString';
import { getHighEntropyValues } from './getHighEntropyValues';
export type OperatingSystem = any;

const WINDOWS_VERSION_MAPPING = {
    '5.1': 'XP',
    '6.0': 'Vista',
    '6.1': '7',
    '6.2': '8',
    '6.3': '8.1',
    '6.4': '10',
    '10.0': '10',
} as Record<string, string>;

interface OsInfo {
    readonly os: OperatingSystem;
    osVersion: string | undefined;
}

// this will not properly calculate windows 11
let cachedOsInfo: OsInfo | undefined;
export function getOsInfoWithoutHints(): OsInfo {
    if (!cachedOsInfo) {
        cachedOsInfo = calculateOsInfo();
    }
    return cachedOsInfo;
}

// the high entropy values do not affect the operating system
export function getOs(): OperatingSystem {
    return getOsInfoWithoutHints().os;
}

// this might ask the user permission to get this information
let cachedOsInfoWithHints: OsInfo | undefined;
export async function getOsInfoWithHints(): Promise<OsInfo> {
    if (!cachedOsInfoWithHints) {
        const osInfo = getOsInfoWithoutHints();
        if (osInfo.os == 'Windows') {
            const entropyValues = await getHighEntropyValues();
            if (typeof entropyValues?.platformVersion === 'string') {
                const majorPlatformVersion = parseInt(entropyValues.platformVersion.split('.')[0]);
                if (majorPlatformVersion >= 13) {
                    osInfo.osVersion = '11';
                }
            }
        }

        cachedOsInfoWithHints = osInfo;
    }
    return cachedOsInfoWithHints;
}

export function calculateOsInfo(): OsInfo {
    let os: OperatingSystem;
    let osVersion: string | undefined = undefined;

    const userAgentString = getUserAgentString();

    if (/(cros)\s[\w]+\s([\w\.]+\w)/i.test(userAgentString)) {
        os = 'Chromium OS';
    } else if (/(iPad|iPhone|iPod)(?=.*like Mac OS X)/i.test(userAgentString)) {
        os = 'iOS';
    } else if (/android/i.test(userAgentString)) {
        os = 'Android';
    } else if (
        /(linux|joli|[kxln]?ubuntu|debian|[open]*suse|gentoo|arch|slackware|fedora|mandriva|centos|pclinuxos|redhat|zenwalk)/i.test(
            userAgentString
        )
    ) {
        os = 'Linux';
    } else if (/(macintosh|mac os x)/i.test(userAgentString)) {
        os = 'Mac OS X';
    } else if (/(windows|win32)/i.test(userAgentString)) {
        os = 'Windows';
        osVersion = getWindowsOsVersion(userAgentString);
    } else {
        os = 'Unknown';
    }

    return { os, osVersion };
}

function getWindowsOsVersion(userAgentString: string): string {
    const winRawVersion = userAgentString.match(new RegExp('Windows NT ([\\d,.]+)'));
    return winRawVersion ? WINDOWS_VERSION_MAPPING[winRawVersion[1]] ?? 'Unknown' : 'Unknown';
}
