import type ErrorDiagnostics from './interfaces/ErrorDiagnostics';
import { getBootFlights } from 'owa-metatags';
import getHeader from './getHeader';
import { getScriptPath } from 'owa-config/lib/bootstrapOptions';
import { scrubForPii } from 'owa-config';

const headersToAddToMiscData: string[] = [
    'X-CalculatedBETarget',
    'X-OWA-CorrelationId',
    'X-MSEdge-Ref',
    'content-encoding',
    'content-length',
    'content-type',
    'X-OWA-Version',
    'X-OWAErrorMessageID',
    'x-diaginfo',
    'x-besku',
    'x-owa-diagnosticsinfo',
    'X-CDN-Provider',
];

let extraMiscData = '';

export function getMiscData(
    errorDiagnostics: ErrorDiagnostics | undefined,
    response: Response | undefined
): string {
    let miscData: string = `&vm=true&sp=${getScriptPath()}`;
    if (errorDiagnostics) {
        miscData += `&ehk=${errorDiagnostics.ehk}&msg=${errorDiagnostics.emsg}`;
    }

    const bootFlights = getBootFlights();
    if (bootFlights && bootFlights.length > 0) {
        miscData += '&bf=' + bootFlights.join(',');
    }

    if (response) {
        miscData += '&req=' + scrubForPii(encodeURIComponent(response.url));
        for (let ii = 0; ii < headersToAddToMiscData.length; ii++) {
            const headerKey = headersToAddToMiscData[ii];
            const headerValue = getHeader(response, headerKey);
            if (headerValue) {
                miscData += `&${headerKey}=${headerValue}`;
            }
        }
        miscData += '&rt=' + response.type;
    }

    const navigation = window.performance?.navigation;
    if (navigation) {
        miscData += `&nt=${navigation.type}`;
    }

    return miscData + extraMiscData;
}

export function appendMiscData(key: string, value: string | number): void {
    extraMiscData += `&${key}=${value}`;
}
