import fetchWithRetry from './fetchWithRetry';
import type RequestOptions from './RequestOptions';
import type MailboxRequestOptions from './MailboxRequestOptions';
import { getConfig } from './config';
import checkAndLogMailboxInfo from './checkAndLogMailboxInfo';
import { getApp } from 'owa-config';
import { debugErrorThatWillShowErrorPopupOnly, trace } from 'owa-trace';
import getUrlWithAddedQueryParameters from 'owa-url/lib/getUrlWithAddedQueryParameters';
import { checkMailboxInfoForDiagnosticData } from './utils/checkMailboxInfoForDiagnosticData';
import { isServiceRequestSupportedForMailbox } from './utils/isServiceRequestSupportedForMailbox';
import { disallowedLicensingCallCheck } from './utils/disallowedLicensingCallCheck';
import { netcoreAPI } from './netcoreAPI';
import { isSuccessStatusCode } from 'owa-http-status-codes';

export function makeServiceRequest<T>(
    actionName: string,
    parameters: any,
    options?: RequestOptions | MailboxRequestOptions
): Promise<T> {
    trace.info(`ServiceAction ${actionName}`, 'service');

    checkMailboxInfoForDiagnosticData(options?.mailboxInfo);
    if (!isServiceRequestSupportedForMailbox(options?.mailboxInfo)) {
        const msg = 'Cannot issue OWS request for a mailbox that does not support service requests';
        debugErrorThatWillShowErrorPopupOnly(msg);
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        throw new Error(msg);
    }

    // Check to make sure this is not a disallowed licensing call
    disallowedLicensingCallCheck(options?.mailboxInfo, actionName);

    if (options?.mailboxInfo?.isRemoved) {
        debugErrorThatWillShowErrorPopupOnly('InvalidRequest: MailboxInfo was removed');
        throw new Error('InvalidRequest: MailboxInfo was removed');
    }

    const config = getConfig();
    checkAndLogMailboxInfo(config, 'Acct-MakeServiceRequestMailbox', options?.mailboxInfo);

    let endpoint;
    if (options?.endpoint) {
        endpoint = options.endpoint;
    } else {
        const baseUrl = options?.customBaseUrl
            ? options.customBaseUrlSubPath
                ? options.customBaseUrl.concat(options.customBaseUrlSubPath)
                : options.customBaseUrl
            : options?.customBaseUrlSubPath
            ? config.baseUrl.concat('/' + options.customBaseUrlSubPath)
            : config.baseUrl;
        endpoint = `${baseUrl}/service.svc?action=${actionName}`;
    }

    if (
        (config.isUserIdle !== undefined && config.isUserIdle()) ||
        options?.isUserActivity == false
    ) {
        endpoint = getUrlWithAddedQueryParameters(endpoint, { UA: '0' });
    }

    endpoint = getUrlWithAddedQueryParameters(
        endpoint,
        { app: getApp() },
        true /* ignore if present */
    );

    if (config.isFeatureEnabled('fwk-netcore-api') && netcoreAPI.has(actionName)) {
        const netcoreEndpoint = endpoint.replace('/owa/', '/owanetcore/');
        return fetchWithRetry(actionName, netcoreEndpoint, 1, options, parameters)
            .then(response => {
                if (typeof response.status === 'number' && !isSuccessStatusCode(response.status)) {
                    throw new Error('owanetcore response did not succeed');
                }
                return response;
            })
            .catch(() => {
                return fetchWithRetry(actionName, endpoint, 1, options, parameters);
            });
    }
    return fetchWithRetry(actionName, endpoint, 1, options, parameters);
}
