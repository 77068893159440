import { LazyBootModule, LazyAction, LazyImport } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootOutlookHeader" */ './lazyFunctions')
);

export const lazyToggleMaximizeProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyToggleMaximize;
    return action.importAndExecute.bind(action);
});

export const lazyCaptionButtonActionProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyCaptionButtonAction;
    return action.importAndExecute.bind(action);
});

export const lazyOneOutlookSuiteHeaderProxy = new LazyImport(lazyModule, m => {
    const action = m.lazyOneOutlookSuiteHeader;
    return action.import.bind(action);
});
