import getAccountSourceListStore from '../store/accountSourceListStore';
import type {
    AccountAndCoprincipalSourceId,
    AccountSource,
    CoprincipalAccountSource,
} from '../store/schema/AccountSourceList';
import { accountRankTypeChecker } from '../store/schema/AccountSourceList';
import assert from 'owa-assert/lib/assert';
import { logGreyErrorFromAccounts } from 'owa-account-analytics';

/**
 * Called within a mutator action this function will add the specified account to the
 * map of sources by sourceId. It is an error to call this for a source that is already
 * in the map.
 * @param account account to be added
 */
export function addSourceToSourcesBySourceIdMap(
    account: AccountSource,
    coprincipalSourceId?: string
) {
    const sourceId = account.sourceId;

    // Pre-condition: the sourceId for the account must not be in the map, and the conprincipal account
    // must be identified (either by the account being coprincipal or having a coprincipal sourceId)
    const isSourceIdPresent = getAccountSourceListStore().sourcesBySourceId.has(account.sourceId);
    const isOrHasCoprincipal =
        accountRankTypeChecker.isCoprincipal(account) || !!coprincipalSourceId;

    if (isSourceIdPresent || !isOrHasCoprincipal) {
        // does not meet the expected pre-conditions, log this non PII information, where
        // isSourceIdPresent is a boolean
        // isOrHasCoprincipal is a boolean
        // account.mailboxRank is a well known string from /shared/internal/owa-client-types/src/MailboxRank.ts
        // account.mailboxInfo.type is a well known string from /shared/internal/owa-client-types/src/MailboxType.ts
        const e = new Error('AcctSourceIdMap-AddPreConditions');
        logGreyErrorFromAccounts('AcctSourceIdMap-AddPreConditions', e, {
            present: isSourceIdPresent,
            coprincipal: isOrHasCoprincipal,
            rank: account.mailboxRank.toString(),
            type: account.mailboxInfo.type.toString(),
        });
    }

    getAccountSourceListStore().sourcesBySourceId.set(sourceId, {
        source: account,
        coprincipalSourceId,
    });
}

/**
 * When a coprincipal account is updated such that the whole account object is replaced, which is needed when
 * modifying MailboxInfo props. The map of objects needs to be updated with the new account object in the store
 * @param account Specifices the coprincipal account that is to be updated
 */
export function updateAccountSourceInSourcesBySourceIdMap(account: CoprincipalAccountSource) {
    getAccountSourceListStore().sourcesBySourceId.set(account.sourceId, {
        source: account,
        coprincipalSourceId: account.sourceId,
    });
}

/**
 * Called within a mutator action this function will remove the specified account from the
 * map of sources by sourceId. It is an error to call this for a source that is not in the map
 * @param sourceId identifier for the source that is to be removed
 */
export function removeSourceFromSourcesBySourceIdMap(sourceId: string) {
    // Pre-condition: the sourceId for the account must be in the map
    assert(
        getAccountSourceListStore().sourcesBySourceId.has(sourceId),
        'SourceId not found is store'
    );
    getAccountSourceListStore().sourcesBySourceId.delete(sourceId);
}

/**
 * Called within a mutator action this function will remove all of the resources
 * associated with the specified coprincipal account.
 * @param coprincipalSourceId identifier for the coprincipal source for which resources are to be removed
 */
export function removeResourcesFromSourcesBySourceIdMap(coprincipalSourceId: string) {
    assert(!!coprincipalSourceId, 'A non empty coprincipalSourceId must be provided');

    const sourceIdsToRemove: string[] = [];
    getAccountSourceListStore().sourcesBySourceId.forEach(
        (value: AccountAndCoprincipalSourceId, key: string) => {
            if (value.coprincipalSourceId === coprincipalSourceId) {
                sourceIdsToRemove.push(key);
            }
        }
    );

    sourceIdsToRemove.forEach(sourceId => removeSourceFromSourcesBySourceIdMap(sourceId));
}
