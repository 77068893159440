import type { TraceableError } from 'owa-trace';
import { addBottleneck, markEnd, markStart } from 'owa-performance';

const preloadedLocstrings: Map<string, Promise<Record<string, string>>> = new Map();
const successfulLoadedUrls: Set<string> = new Set();
const outstandingPromises: {
    [url: string]: Promise<Record<string, string>>;
} = {};
const timingName = 'locales';

export async function fetchLocstrings(
    url: string,
    fetchSource?: string,
    trackTiming?: boolean
): Promise<Record<string, string> | undefined> {
    const preloadPromise = preloadedLocstrings.get(url);
    if (!fetchSource && preloadPromise) {
        preloadedLocstrings.delete(url);
        return preloadPromise;
    }

    // if we have already successfully loaded this url, then we don't need to do anything
    if (successfulLoadedUrls.has(url)) {
        return undefined;
    }
    if (trackTiming) {
        markStart(timingName);
    }
    let resourcePromise = outstandingPromises[url];
    if (!resourcePromise) {
        addBottleneck('lc_s', fetchSource || 'wp');
        resourcePromise = fetchAndLoad(url);
        if (trackTiming) {
            resourcePromise.then(() => {
                markEnd(timingName);
            });
        }

        // if the promise fails, then let's do a retry
        outstandingPromises[url] = resourcePromise = resourcePromise.catch(() =>
            fetchAndLoad(url, true /* isRetry */)
        );

        resourcePromise
            .then(() => {
                successfulLoadedUrls.add(url);
                delete outstandingPromises[url];
            })
            .catch(() => {
                delete outstandingPromises[url];
            });

        if (fetchSource) {
            preloadedLocstrings.set(url, resourcePromise);
        }
    } else if (trackTiming) {
        markEnd(timingName);
    }
    return resourcePromise;
}

const retryStrategyQsp = 'bO=1';

// Search for parent directory paths (eg., "<version>/scripts/../resources/")
const PARENT_DIRECTORY_REGEX = /\/[^/]+\/\.\.\//g;

function cleanupParentDirectoryReferences(url: string): string {
    while (PARENT_DIRECTORY_REGEX.test(url)) {
        url = url.replace(PARENT_DIRECTORY_REGEX, '/');
    }
    return url;
}

async function fetchAndLoad(originalUrl: string, isRetry?: boolean) {
    let url = cleanupParentDirectoryReferences(originalUrl);
    // Small hack to avoid pulling in a ton of code around owa-url
    if (isRetry && !/bO=\d+/.test(url)) {
        url = `${url}${url.includes('?') ? '&' : '?'}${retryStrategyQsp}`;
    }

    // Request the strings
    let response: Response;
    try {
        response = await fetch(url);
    } catch (err) {
        throw createError('fetch', err, url, true, 0); // Network error
    }

    // Handle error statuses
    const status = response.status;
    if (status != 200) {
        throw createError(
            'status',
            null,
            url,
            status >= 500 || status === 404 || status == 418, // Network error depending on HTTP status code
            status
        );
    }

    let responseText: string | undefined;

    // Parse the response
    try {
        responseText = await response.text();
        const locstrings = JSON.parse(responseText);

        return locstrings;
    } catch (err) {
        throw createError('parse', err, url, false, status, responseText); // Not a network error
    }
}

function createError(
    stage: string,
    innerError: Error | null,
    url: string,
    networkError: boolean,
    status?: number,
    responseText?: string
): Error {
    const error: TraceableError = new Error('Failed to load localized strings');

    networkError =
        networkError ||
        innerError instanceof DOMException || // Abort exception: See https://developer.mozilla.org/en-US/docs/Web/API/fetch#exceptions
        innerError instanceof TypeError; // Network error: See https://developer.mozilla.org/en-US/docs/Web/API/fetch#exceptions

    error.diagnosticInfo = JSON.stringify({
        stage,
        url,
        status,
        innerError: innerError?.message,
        // Due to some bug, presumed in the SW, some requests are getting the index page back.
        // Rather than record the entire response text, just record whether it is the index page
        response: !!responseText
            ? responseText.indexOf('<meta name="scriptVer" ') > -1
                ? 'index page'
                : 'unknown'
            : 'none',
    });

    // Webpack adds the `request` property to errors that arise due to network issues.  We
    // do the same thing here so that LazyModule knows that it can retry this import.
    error.request = url;

    if (networkError) {
        error.networkError = true;
    }

    error.httpStatus = status;
    return error;
}
