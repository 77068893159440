import { logGreyErrorFromAccounts } from 'owa-account-analytics';
import assert from 'owa-assert/lib/assert';
import setCoprincipalAccountBootStateMutator from '../actions/setCoprincipalAccountBootState';
import onCoprincipalBootStateChanged from '../actions/onCoprincipalBootStateChanged';
import { default as getAccountBySourceId } from '../selectors/getAccountBySourceId';
import getAccountSourceListStore from '../store/accountSourceListStore';
import { accountRankTypeChecker } from '../store/schema/AccountSourceList';
import { BootState } from '../store/schema/BootState';
import type { BootStateData } from '../store/schema/BootStateData';
import type { CoprincipalAccountSource } from '../store/schema/AccountSourceList';

// Define the type for the callback when an account is added
type OnAccountAddedCallback = (accountSource: CoprincipalAccountSource) => void;

// TECHNICAL DEBT: In monarch, calendar currently initializes the owa-accounts-store based on
// the information in the owa-account-source-list-store. Because we do not reload after adding an
// account we need a way for calendar to be notified when additional accounts are added. The
// coprincipalAccountAddedCallback callback will be called when an account is added and the
// pre-render initialization of the account is complete.
let coprincipalAccountAddedCallback: OnAccountAddedCallback | undefined = undefined;

export function setOnCoprincipalAccountAdded(callback: OnAccountAddedCallback) {
    coprincipalAccountAddedCallback = callback;
}

/**
 * Validates that the sourceId is valid and then sets the account's boot state
 * @param sourceId id of the coprincipal account
 * @param state state to which the account is to be set
 */
export default function setCoprincipalAccountBootState(
    sourceId: string,
    state: BootState,
    data?: BootStateData
): void {
    const account = getAccountBySourceId(sourceId);
    if (!accountRankTypeChecker.isCoprincipal(account)) {
        // In theory this would only happen if an account was deleted while boot state
        // was processing, so we want to log the failure but not throw an error.
        const errorMessage = 'AcctInit-Cannot set BootState, coprincipal account is not in store';
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        const e = new Error(errorMessage);

        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logGreyErrorFromAccounts(errorMessage, e, {
            cnt: getAccountSourceListStore().sources.length,
            sid: typeof sourceId,
            sidl: sourceId.length,
        });

        return;
    }

    if (account.bootState === state) {
        // The boot state has not changed
        return;
    }

    setCoprincipalAccountBootStateMutator(sourceId, state, data);

    const updatedAccount = getAccountBySourceId(sourceId);

    // We use an assert here rather log because we got the source above and nothing should have removed it
    assert(updatedAccount, 'Must have been able to find updated account by sourceId');

    onCoprincipalBootStateChanged(updatedAccount, state);

    // If we are pre-render complete let the caller registered for notifications know
    if (
        state === BootState.StartupComplete &&
        accountRankTypeChecker.isCoprincipal(updatedAccount) &&
        coprincipalAccountAddedCallback
    ) {
        coprincipalAccountAddedCallback(updatedAccount);
    }
}
