import {
    createBposResolver,
    getBposNavBarDataAsync,
    trySetBposNavBarData,
} from 'owa-bpos-store/lib/bposNavBarData';
import { isHosted } from 'owa-config';
import { getItem } from 'owa-local-storage';
import { LocalStoreLocaleKey, preloadLocStrings } from 'owa-localize-internal';
import { isBootFeatureEnabled } from 'owa-metatags';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import type { StartConfig } from 'owa-shared-start';
import { fetchThemeBackgroundImage, fetchThemeFile, ThemeConstants } from 'owa-theme-shared';

const localStorageSource = 'ls';
const functionName = 'start';

export const getRunAfterRequests = (config: StartConfig) => {
    const oldRunAfterRequests = config.runAfterRequests;
    return (sessionDataPromise: Promise<SessionData>) => {
        // try to load the theme data from local storage
        const cachedNormalizedTheme = getItem(window, ThemeConstants.LOCAL_STORAGE_KEY);
        if (cachedNormalizedTheme) {
            fetchThemeFile(cachedNormalizedTheme, localStorageSource);
        }

        const cachedThemeImage = getItem(window, ThemeConstants.LOCAL_IMAGE_STORAGE_KEY);
        if (cachedThemeImage) {
            fetchThemeBackgroundImage(cachedThemeImage);
        }

        const cachedLocale = getItem(window, LocalStoreLocaleKey);
        if (cachedLocale) {
            document.documentElement.lang = cachedLocale;
        }

        preloadLocStrings(localStorageSource);

        // load the bpos data as soon as possible so we can start rendering the header as soon as possible
        if (config.loadBpos) {
            const resolveBpos = createBposResolver();
            sessionDataPromise.then(sd => {
                // we only call getBposNavBarDataAsync if the NavBarData is not already set
                // Bookings has a custom NavBarData that we don't want to overwrite
                const hasBposData = sd?.owaUserConfig?.NavBarData && config.app !== 'Bookings';

                // consumer users don't need NavBarData and we can set it to null
                const isConsumer = sd?.owaUserConfig?.SessionSettings?.WebSessionType !== 0;

                if (isHosted() && isBootFeatureEnabled('fwk-skipnavbardataonhosted')) {
                    // Hosted scenarios should not need NavBarData
                    resolveBpos(() => trySetBposNavBarData(null));
                } else if (hasBposData || isConsumer) {
                    resolveBpos(() => trySetBposNavBarData(sd?.owaUserConfig?.NavBarData ?? null));
                } else {
                    resolveBpos(() => getBposNavBarDataAsync(functionName, config.app));
                }
            });
        }

        // Initialize localization once we have the user culture
        sessionDataPromise
            .then(() => {
                preloadLocStrings('sd');
            })
            .catch(() => {});

        return oldRunAfterRequests?.(sessionDataPromise);
    };
};
