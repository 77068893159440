import { default as joinPath, ensureLeadingSlash, ensureTrailingSlash } from './joinPath';
import { getHostLocation } from './hostLocation';
import getRootVdirName from './getRootVdirName';

export default function getFullPathForAuthRedirect(rootPath: string): string {
    let scopedPath = rootPath;
    const windowUrlPath = getHostLocation()?.pathname;

    if (windowUrlPath) {
        // Normalize by trimming the slashes from start and end of windowUrlPath after separated from vDir.
        const vDir = getRootVdirName() || '';
        const pathSegments = windowUrlPath.split('/').filter(segment => segment);
        const vDirIndex = pathSegments.indexOf(trimSlashes(vDir));
        if (vDirIndex > -1) {
            pathSegments.splice(vDirIndex, 1);
        }
        const postVdirPath = pathSegments.join('/');
        scopedPath = joinPath(scopedPath, postVdirPath);
    }
    return ensureLeadingSlash(ensureTrailingSlash(scopedPath));
}

export function trimSlashes(str: string) {
    return str.replace(/^\/+|\/+$/g, '');
}
