import { isObservable } from 'mobx';
import type { SerializationIssue } from '../types/SerializationTypes';

interface DeepObjectQueueItem {
    key: string | number;
    parent: any;
}

type DeepObjectQueue = DeepObjectQueueItem[];

const MAX_DEEP_SEARCH = 5000;

export function traverseObjectDeep<Results extends SerializationIssue | boolean>(
    obj: object,
    functionToCall: (parent: any, key: string | number) => Results | null
): Results[] {
    const issues: Results[] = [];
    const queue: DeepObjectQueue = [];
    addChildrenToQueue(queue, obj);

    let currentEventName: string | null = null;

    let count = 0;
    while (queue.length > 0 && count++ <= MAX_DEEP_SEARCH) {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        const { parent, key } = queue.shift()!;

        const value = parent[key];

        if (key === 'eventName' || key === 'EventName') {
            currentEventName = value;
        }

        const foundIssue: Results | null = functionToCall(parent, key);
        if (foundIssue) {
            if (currentEventName && typeof foundIssue !== 'boolean') {
                foundIssue.eventName = currentEventName;
            }
            issues.push(foundIssue);
        }

        // Do not traverse an observable object. It can cause an infinite loop.
        if (value && (typeof value === 'object' || Array.isArray(value)) && !isObservable(value)) {
            addChildrenToQueue(queue, value);
        }
    }

    return issues;
}

function addChildrenToQueue(queue: DeepObjectQueue, objectOrArray: any) {
    if (Array.isArray(objectOrArray)) {
        return objectOrArray.map((_child, index) => addChildToQueue(queue, objectOrArray, index));
    } else if (typeof objectOrArray === 'object') {
        return Object.keys(objectOrArray).map(key => addChildToQueue(queue, objectOrArray, key));
    }

    return [];
}

function addChildToQueue(queue: DeepObjectQueue, parent: any, key: string | number) {
    queue.push({ parent, key });
}
