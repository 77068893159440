import { getItem, setItem } from 'owa-local-storage';
import { calculateBootType } from './calculateBootType';
import type { BootType } from './types/BootType';

export interface EnvDiagnostics extends EnvDiagnosticsOnResponse {
    bt?: BootType; // Boot Type
}

interface EnvDiagnosticsOnResponse {
    fe?: string; // Web Service FE Server
    be?: string; // Web Service BE Server
    wsver?: string; // Web Service Version
    te?: string; // Through Edge
    dag?: string; // The Dag name
    fost?: string; // The forest name
}

let cachedBootType: BootType | null = null;

// This is only called in the index.html case
const diagnostics: EnvDiagnostics = {};
const EnvDiagnosticsStorageKey = 'EnvDiagnostics';

let hasUpdatedOnResponse = false;
export function updateDiagnosticsOnResponse(response: Response) {
    // we only need to run this once per session
    if (!hasUpdatedOnResponse) {
        hasUpdatedOnResponse = true;
        updateField('fe', getHeaderValue(response, 'X-FEServer'), true /* forceUpdate */);
        updateField('be', getHeaderValue(response, 'X-BEServer'), true /* forceUpdate */);
        updateField('wsver', getHeaderValue(response, 'X-OWA-Version'), true /* forceUpdate */);
        let forest = getHeaderValue(response, 'x-owa-forest');
        if (forest) {
            forest = forest.toLowerCase().startsWith('prod')
                ? 'NAMPRD01'
                : forest.substring(0, forest.indexOf('.')).toUpperCase();
        }
        updateField('fost', forest, true /* forceUpdate */);
        updateField(
            'dag',
            getHeaderValue(response, 'x-owa-dag')?.toUpperCase(),
            true /* forceUpdate */
        );

        updateField(
            'te',
            getHeaderValue(response, 'X-MSEdge-Ref') ? '1' : '0',
            true /* forceUpdate */
        );

        setItem(self, EnvDiagnosticsStorageKey, JSON.stringify(diagnostics));
    }
}

let hasUpdatedFromStorage = false;
/*
 * This function should only be used as part of offline/local boot implementation
 * Everything else should rely on actual network SD results to populate diagnostics data
 */
export function updateDiagnosticsFromStorage() {
    // if it was already updated from response it should have the most current data
    if (!hasUpdatedFromStorage && !hasUpdatedOnResponse) {
        hasUpdatedFromStorage = true;
        const diagnosticsData = getItem(self, EnvDiagnosticsStorageKey);
        if (diagnosticsData) {
            try {
                Object.entries<string>(JSON.parse(diagnosticsData)).forEach(([key, value]) => {
                    // we don't want to restore previous boot type if available
                    if (key === 'bt') {
                        return;
                    }
                    updateField(key as keyof EnvDiagnosticsOnResponse, value);
                });
            } catch (e) {
                // ignore
            }
        }
    }
}

function getHeaderValue(response: Response, headerName: string): string | null {
    return response?.headers?.get(headerName);
}

function updateField(
    key: keyof EnvDiagnosticsOnResponse,
    value?: string | null,
    forceUpdate?: boolean
) {
    if (!diagnostics[key] || forceUpdate) {
        diagnostics[key] = value || 'Unknown';
    }
}

export function getBackend() {
    return diagnostics.be;
}

export function getFrontend() {
    return diagnostics.fe;
}

export function getThroughEdge() {
    return diagnostics.te;
}

export function getServerVersion() {
    return diagnostics.wsver;
}

export function getDag() {
    return diagnostics.dag;
}

export function getForest() {
    return diagnostics.fost;
}

export async function getBootType() {
    if (cachedBootType) {
        return cachedBootType;
    }

    let bootType = diagnostics.bt;
    if (!bootType && self.performance?.timing) {
        bootType = diagnostics.bt = await calculateBootType(self.performance.timing.fetchStart);
        cachedBootType = bootType;
    }
    return bootType;
}
