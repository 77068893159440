import { logGreyErrorFromAccounts } from 'owa-account-analytics';

import removeCoprincipalAccountByIdMutator from '../actions/removeCoprincipalAccountById';
import onPreCoprincipalAccountRemoved from '../actions/onPreCoprincipalAccountRemoved';

import getAccountSourceListStore from '../store/accountSourceListStore';
import type { AccountSource } from '../store/schema/AccountSourceList';
import { accountSourceDataTypeChecker } from '../store/schema/AccountSourceList';
import { updateCoprincipalUserIdentities } from './coprincipalUserIdentities';

import { toRemovedAccount } from './toRemovedAccount';

/**
 * Logs when an unexpected account removal happens
 * @param message Message that identifies the unexpected situation
 * @param accountSouceId sourceId of the account to be removed
 * @param account The accounts for which a removal was not expected
 * @param additionalOptions Additional options to add to the diagnostic object
 */
function logUnexpectedCoprincipalAccountRemoval(
    message: string,
    accountSourceId: string,
    account?: AccountSource,
    additionalOptions?: any
) {
    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
     * Error constructor names can only be a string literals.
     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
    const e = new Error(message);

    // dataType is defined by the AccountDataType enumeration and is not EUII
    // accountSourceMailboxType is defined by the AccountSourceMailboxType enumeration and is not EUII
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
    logGreyErrorFromAccounts(message, e, {
        cnt: getAccountSourceListStore().sources.length,
        dt: account?.dataType?.toString(),
        mt: accountSourceDataTypeChecker.isM365Mailbox(account)
            ? (account as any)?.accountSourceMailboxType?.toString()
            : undefined,
        sid: typeof accountSourceId,
        sidl: accountSourceId.length,
        ...additionalOptions,
    });
}

/**
 * Gets the list of non-connected accounts, in Monarch this is expected to be the full list
 * of accounts. In OWA it should always be the default account
 * @returns All of the non-connected accounts
 */
function getNonConnectedAccounts(): AccountSource[] {
    return getAccountSourceListStore().sources.filter(
        account => !accountSourceDataTypeChecker.isM365ConnectedMailbox(account)
    );
}

/**
 * Performs pre-account removal and then removes the account
 */
export default function removeCoprincipalAccountById(accountSourceId: string): void {
    const store = getAccountSourceListStore();

    const matchingAccountIndex = store.sources.findIndex(
        accountSource => accountSource.sourceId === accountSourceId
    );
    if (matchingAccountIndex < 0) {
        // There are no accounts in the source list store
        logUnexpectedCoprincipalAccountRemoval('AcctRemove-AccountNotFound', accountSourceId);
        return;
    }

    if (store.sources.length <= 1) {
        // The client-web code relies on there being at least one source in the list do not
        // remove the last source from the account source list store
        logUnexpectedCoprincipalAccountRemoval(
            'AcctRemove-CannotRemoveLastAccount',
            accountSourceId,
            store.sources[0]
        );
        return;
    }

    // If the account we are removing is not a connected account, then we need to
    // check and make sure there is at least one other non-connected account.
    const isConnectedAccount = accountSourceDataTypeChecker.isM365ConnectedMailbox(
        store.sources[matchingAccountIndex]
    );
    if (!isConnectedAccount) {
        const nonConnectedAccounts = getNonConnectedAccounts();
        if (nonConnectedAccounts.length <= 1) {
            // Cannot remove the default account in OWA
            logUnexpectedCoprincipalAccountRemoval(
                'AcctRemove-CannotRemoveDefaultAccount',
                accountSourceId,
                nonConnectedAccounts[0],
                { ncnt: nonConnectedAccounts.length }
            );
            return;
        }
    }

    const removedAccount = toRemovedAccount(store.sources[matchingAccountIndex]);
    onPreCoprincipalAccountRemoved(store.sources[matchingAccountIndex]);
    removeCoprincipalAccountByIdMutator(accountSourceId, removedAccount);
    updateCoprincipalUserIdentities();
}
