import assert from 'owa-assert/lib/assert';
import { mutatorAction } from 'satcheljs';
import getAccountBySourceId from '../selectors/getAccountBySourceId';
import getAccountSourceListStore from '../store/accountSourceListStore';

/**
 * Update the displayName of the specified Coprincipal account source in the store
 */
export default mutatorAction(
    'UPDATE_COPRINCIPAL_ACCOUNT_DISPLAY_NAME',
    function (sourceId: string, displayName: string): void {
        assert(getAccountBySourceId(sourceId), 'Cannot update account not in store');
        const store = getAccountSourceListStore();
        store.sources.forEach(source => {
            if (source.sourceId === sourceId) {
                source.displayName = displayName;
            }
        });
    }
);
