import type MailboxInfo from './MailboxInfo';
import getIndexerValueForMailboxInfo from './getIndexerValueForMailboxInfo';
import isSameCoprincipalAccountIndexers from './isSameCoprincipalAccountIndexers';

export default function isSameCoprincipalAccountMailboxInfos(
    mailboxInfo1: MailboxInfo | undefined,
    mailboxInfo2: MailboxInfo | undefined
): boolean {
    let isSame = false;

    if (mailboxInfo1 && mailboxInfo2) {
        const mailbox1Index = getIndexerValueForMailboxInfo(mailboxInfo1);
        const mailbox2Index = getIndexerValueForMailboxInfo(mailboxInfo2);

        isSame = isSameCoprincipalAccountIndexers(mailbox1Index, mailbox2Index);
    }

    return isSame;
}
