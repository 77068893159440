import assert from 'owa-assert/lib/assert';
import { mutatorAction } from 'satcheljs';
import getAccountBySourceId from '../selectors/getAccountBySourceId';
import getAccountSourceListStore from '../store/accountSourceListStore';
import { accountSourceDataTypeChecker } from '../store/schema/AccountSourceList';

/**
 * Update the specified AccountSource in the store
 */
export default mutatorAction(
    'UPDATE_CONNECTED_ACCOUNT_STATE',
    function (sourceId: string, accountState: number): void {
        assert(getAccountBySourceId(sourceId), 'Cannot update account not in store');

        const store = getAccountSourceListStore();
        store.sources.forEach(source => {
            if (
                source.sourceId === sourceId &&
                accountSourceDataTypeChecker.isM365ConnectedMailbox(source)
            ) {
                source.accountState.connectedAccountState = accountState;
            }
        });
    }
);
