import { logGreyErrorFromAccounts } from 'owa-account-analytics';

import setSharedSourcesMutator from '../actions/setSharedSources';
import onSharedSourcesChanged from '../actions/onSharedSourcesChanged';
import getAccountBySourceId from '../selectors/getAccountBySourceId';
import type { AdditionalAccountInfo } from '../store/schema/AccountSourceList';

/**
 * Sets the shared sources and then notifies of changes to the shared sources
 * @param coprincipalSourceId SourceId of the account for which shared sources are to be set
 * @param sharedAccountInfo Information to be set
 */
export default function setSharedSources(
    coprincipalSourceId: string,
    sharedAccountInfo: AdditionalAccountInfo
): void {
    const account = getAccountBySourceId(coprincipalSourceId);
    if (!account) {
        // This should only happen if the account is removed while the network call(s) to get information
        // about shared mailboxes (auto-mapped or manually added) is happening.
        const errorMessage = 'AcctSetSharedSources-AccountNotFound';
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * Error constructor names can only be a string literals.
         *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
        const e = new Error(errorMessage);
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logGreyErrorFromAccounts(errorMessage, e);

        return;
    }

    setSharedSourcesMutator(coprincipalSourceId, sharedAccountInfo);
    onSharedSourcesChanged(account);
}
