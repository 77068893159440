import { errorThatWillCauseAlert } from './trace';
import type { TraceErrorObject } from './TraceErrorObject';
import { EmptyErrorStack } from './constants';

export default function onGlobalError(
    message: string,
    filename?: string,
    lineno?: number,
    colno?: number,
    error?: TraceErrorObject,
    stack?: string
) {
    // If we don't have a message or an error then we have no information about the error
    if (message || error) {
        if (!error) {
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            error = new Error(message);
            error.stack = stack || EmptyErrorStack;
        }
        message = message || error.message;

        if (message.indexOf('"responseText":"<!DOCTYPE') != -1) {
            const start = message.toLocaleLowerCase().indexOf('<!doctype html');
            const secondPart = message.substring(start);
            const end =
                secondPart.toLocaleLowerCase().indexOf('</html>\r\n') !== -1
                    ? secondPart.toLocaleLowerCase().indexOf('</html>\r\n') + 9
                    : secondPart.toLocaleLowerCase().indexOf('</html>') + 7;

            if (start !== -1 && end !== -1) {
                message =
                    message.substring(0, start) +
                    'ReplacedGenericHTMLErrorMessage' +
                    message.substring(start + end);
            }
        }

        // Making sure we can extend the error
        if (Object.isExtensible(error)) {
            // using Object.defineProperty because this properties can be read only some times
            try {
                if (filename) {
                    Object.defineProperty(error, 'filename', { value: filename });
                }
                if (typeof lineno == 'number') {
                    Object.defineProperty(error, 'lineno', { value: lineno });
                }
                if (typeof colno == 'number') {
                    Object.defineProperty(error, 'colno', { value: colno });
                }
            } catch {
                /* no op */
            }
        }

        const alreadyReported = error?.reported;
        if (!alreadyReported) {
            // this will get picked up by any trace listeners for error that are registered globally
            errorThatWillCauseAlert(message, error);
        }
    }
}
