import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type TokenResponseCode from 'owa-service/lib/contract/TokenResponseCode';

export const REDIRECT_URI_SUFFIX = '/mail/oauthRedirect.html';

// This is to make sure that the Consumers are redirected to msn.com after logout
export const POST_LOGOUT_REDIRECT_URI_CONSUMER = 'https://www.msn.com/';

export const POST_LOGOUT_REDIRECT_URI_CONSUMER_SUFFIX = '/mail/ConsumerSignout.html';

// Here https://www.microsoft.com/ is not a registered reply url.
// This is a temporary fix to make sure that the Enterprise users stay at the logout page
// after logout. For enterprise scenario we don't want them to be redirected anywhere.
// But not setting the postlogoutredirecturi at all in Enterprise scenarios,
// somehow is picking up https://outlook-sdf.office.com/mail as the postlogoutredirecturi.
// But the problem with taking the user to /mail is it invokes the login flow.
// The long term fix here is that the MSAL team adds support for a param in the logout request to ensure that
// the user is not redirected any where and stays on the logout page.
export const POST_LOGOUT_REDIRECT_URI_ENTERPRISE = 'https://www.microsoft.com/';

export const POST_LOGOUT_REDIRECT_URI_SHARED_ABT = '/mail/ABTSignout.html';

export const LOGOUT_REDIRECT_URI_SUFFIX = '/owa/logoff.owa';
export const AUTHORITY_COMMON = 'https://login.microsoftonline.com/common/';
export const AUTHORITY_ORGANIZATIONS = 'https://login.microsoftonline.com/organizations/';
export const AUTHORITY_CONSUMERS = 'https://login.microsoftonline.com/consumers/';
export const MSA_DEFAULT_SCOPE = 'service::outlook.office.com::MBI_SSL';
export const DC_QUERY_PARAM = 'ESTS-PUB-WUS2-AZ1-FD000-TEST1';
export const EXCHANGE_CONSUMER_TENANT_ID = '84df9e7f-e9f6-40af-b435-aaaaaaaaaaaa';
export const MICROSOFT_CONSUMER_TENANT_ID = '9188040d-6c67-4c5b-b112-36a304b66dad';
export const PENDING_REDIRECT_KEY = 'olk-msal-redirect';
export const OUTLOOK_GATEWAY_URL = 'outlookgatewayb2/.*/graphql';
export const NOTIFICATION_CHANNEL_URL = '.*/notificationchannel';
export const RESOURCE_BLOCK_LIST_CONSUMER: string[] = [
    'https://cortana.ai',
    'https://dataservice.o365filtering.com',
    'https://outlookimageproxy.azurewebsites.net',
];
export const RESOURCE_BLOCK_LIST_ENTERPRISE: string[] = [
    'https://cortana.ai',
    'https://dataservice.o365filtering.com',
    'https://outlookimageproxy.azurewebsites.net',
    'https://outlook-sdf.live.com',
    'https://outlook.live.com',
];

export const TOKEN_RESPONSE_UNSUPPORTED_ACCOUNT_TYPE: TokenResponse = {
    TokenResultCode: 2,
    SubErrorCode: 'unsupported_account_type',
};

export const TOKEN_RESPONSE_MSAL_NOT_ENABLED: TokenResponse = {
    TokenResultCode: 2,
    SubErrorCode: 'msal_not_enabled',
};

export const OUTLOOK_COBRAND_ID = 'ab0455a0-8d03-46b9-b18b-df2f57b9e44c';

/**
 * identifierUris registered to EXO (00000002-0000-0ff1-ce00-000000000000).
 */
export const EXO_RESOURCE_IDENTIFIER_URIS = [
    /^https:\/\/outlook(-[st]df)?\.office(365)?\.com\/?$/i,
];

/**
 * Contains valid Outlook enterprise domains supported by MSAL. These URLs
 * should be registered as redirectUris, otherwise redirect errors will occur.
 */
export const OWA_MSAL_ENTERPRISE_PUBLIC_ORIGINS = [
    /^https:\/\/outlook(-[st]df)?\.office(365)?\.com$/i,
    /^https:\/\/(sdf.)?outlook\.cloud\.microsoft$/i,
];

/**
 * Contains valid Places domains supported by MSAL. These URLs should be
 * registered as redirectUris, otherwise redirect errors will occur.
 */
export const PLACES_MSAL_ENTERPRISE_PUBLIC_ORIGINS = [
    /^https:\/\/places(-sdf)?\.cloud\.microsoft$/i,
];

/**
 * Contains valid enterprise domains supported by MSAL. These URLs should be
 * registered as redirectUris, otherwise redirect errors will occur.
 */
export const MSAL_ENTERPRISE_PUBLIC_ORIGINS = [
    ...OWA_MSAL_ENTERPRISE_PUBLIC_ORIGINS,
    ...PLACES_MSAL_ENTERPRISE_PUBLIC_ORIGINS,
];

export const OWA_BASE_PATH = 'https://outlook.office.com';
