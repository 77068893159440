import { LazyBootModule, LazyImport, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootToggle" */ './lazyIndex')
);

export const lazyOutlookToggle = new LazyImport(lazyModule, m => m.OutlookToggle);

export const lazyGetToggleStateProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyGetToggleState;
    return action.importAndExecute.bind(action);
});
