import { mutatorAction } from 'satcheljs';

import getAccountSourceListStore from '../store/accountSourceListStore';

import type { CoprincipalAccountSource } from '../store/schema/AccountSourceList';
import { addSourceToSourcesBySourceIdMap } from '../utils/sourcesBySourceIdMapUtils';
import buildIndexerByUserIdentityMap from '../utils/buildIndexerByUserIdentityMap';

export default mutatorAction(
    'ADD_COPRINCIPAL_ACCOUNT',
    function (accountSource: CoprincipalAccountSource): void {
        const store = getAccountSourceListStore();
        const addedAt = store.sources.push(accountSource) - 1;

        // we want to add the object from the store, not the one passed in to the
        // sourcesBySourceIdMap
        const sourceInArray = store.sources[addedAt];
        addSourceToSourcesBySourceIdMap(sourceInArray);
        store.indexerByUserIdentity = buildIndexerByUserIdentityMap(store);

        if (!store.globalSettingsSourceId) {
            store.globalSettingsSourceId = sourceInArray.sourceId;
        }
    }
);
