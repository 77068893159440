import { trace } from 'owa-trace';
import { mutatorAction } from 'satcheljs';
import getAccountSourceListStore from '../store/accountSourceListStore';
import type { BootState } from '../store/schema/BootState';
import type { BootStateData } from '../store/schema/BootStateData';
import { accountRankTypeChecker } from '../store/schema/AccountSourceList';

export default mutatorAction(
    'SET_COPRINCIPAL_ACCOUNT_BOOT_STATE',
    function (sourceId: string, state: BootState, data?: BootStateData): void {
        getAccountSourceListStore().sources.forEach(account => {
            if (accountRankTypeChecker.isCoprincipal(account) && account.sourceId === sourceId) {
                account.bootState = state;
                account.bootStateData = data;

                trace.info(
                    `[source-list-store] Setting coprincipal account boot state to ${account.bootState}`
                );
            }
        });
    }
);
