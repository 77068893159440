import { setItem, getItem } from 'owa-local-storage';
import type { LogicalRingEnum } from 'owa-client-pie/lib/outlookwebcontext.g';

const LogicalRingKey = 'LogicalRing';
export type LogicalRing = keyof typeof LogicalRingEnum;
let logicalRing: LogicalRing | null;

export function setLogicalRingForNonPrimaryAccount(ring: LogicalRing, mailboxSmtpAddress: string) {
    if (!!logicalRing && logicalRing === ring) {
        return;
    }
    logicalRing = ring;
    setItem(self, LogicalRingKey + '_' + mailboxSmtpAddress, ring);
}

export function getLogicalRingForNonPrimaryAccount(mailboxSmtpAddress: string): LogicalRing | null {
    if (!logicalRing) {
        logicalRing = getItem(
            self,
            LogicalRingKey + '_' + mailboxSmtpAddress
        ) as LogicalRing | null;
    }

    return logicalRing;
}
