import type { MailboxInfo } from 'owa-client-types';
import { isDisallowedLicensingCall } from './isDisallowedLicensingCall';
import { errorThatWillCauseAlert, type TraceErrorObject } from 'owa-trace';

/**
 * Checkes to make sure this is not a disallowed licensing call, and if it is, raises an alert
 * and throws an exception.
 * @param mailboxInfo Specifies the source that will be connected to
 * @param action Specifies the action being performed
 */
export function disallowedLicensingCallCheck(mailboxInfo?: MailboxInfo, action?: string) {
    if (isDisallowedLicensingCall(mailboxInfo, action)) {
        const diagnosticInfo = {
            mailboxType: mailboxInfo?.type,
            mailboxProvider: mailboxInfo?.mailboxProvider,
            action,
        };

        const error: TraceErrorObject = new Error('DisallowedLicensingCall');
        error.diagnosticInfo = JSON.stringify(diagnosticInfo);
        errorThatWillCauseAlert(error);
    }
}
