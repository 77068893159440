import { trace } from 'owa-trace';
import { mutatorAction } from 'satcheljs';
import getAccountSourceListStore from '../store/accountSourceListStore';
import type {
    CoprincipalAccountSource,
    AccountSourceList,
} from '../store/schema/AccountSourceList';
import type { AdditionalMailboxInfoProps } from 'owa-account-source-list-types';
import { updateAccountSourceInSourcesBySourceIdMap } from '../utils/sourcesBySourceIdMapUtils';
import buildIndexerByUserIdentityMap from '../utils/buildIndexerByUserIdentityMap';

type AccountAndIndex = {
    account: CoprincipalAccountSource;
    index: number;
};

function getAccountAndIndexForSourceId(
    store: AccountSourceList,
    sourceId: string
): AccountAndIndex | undefined {
    const index = store.sources.findIndex(account => account.sourceId === sourceId);
    if (index === -1) {
        return undefined;
    }

    return {
        account: store.sources[index],
        index,
    };
}

export default mutatorAction(
    'SET_COPRINCIPAL_ADDITIONAL_MAILBOX_PROPS',
    function (sourceId: string, additionalProps: AdditionalMailboxInfoProps): void {
        const store = getAccountSourceListStore();
        const accountAndIndex = getAccountAndIndexForSourceId(store, sourceId);
        if (!accountAndIndex) {
            trace.warn(
                '[source-list-store] Account not found for sourceId when setting additional properties'
            );
            return;
        }

        const updatedAccount = {
            ...accountAndIndex.account,
            mailboxInfo: { ...accountAndIndex.account.mailboxInfo, ...additionalProps },
        };

        store.sources[accountAndIndex.index] = updatedAccount;

        // Make sure the sourcesBySourceIdMap is updated with the new object by first removing the old mappings
        // and then adding the new mappings
        const sourceInArray = store.sources[accountAndIndex.index];
        updateAccountSourceInSourcesBySourceIdMap(sourceInArray);
        store.indexerByUserIdentity = buildIndexerByUserIdentityMap(store);
    }
);
