let fault: Error | undefined;

export function getDataWorkerLeaderFault() {
    return fault;
}

export function getIsDataWorkerLeaderFaulted() {
    return !!fault;
}

export function setIsDataWorkerLeaderFaulted(e: Error | undefined) {
    fault = e;
}
