import type { WindowData } from 'owa-window-data';
import { getWindowData } from 'owa-window-data';
import { isBootFeatureEnabled } from 'owa-metatags';

const owaPrefix = 'olk-';

export function getOwaPrefixedKey(key: string): string {
    return owaPrefix + key;
}

export function setItem(windowObj: Window, key: string, value: string): void {
    if (localStorageExists(windowObj)) {
        try {
            const isEnabled = isBootFeatureEnabled('localStorageOwaPrefix');
            const actualKey = isEnabled ? owaPrefix + key : key;
            const actualOldKey = isEnabled ? key : owaPrefix + key;
            windowObj.localStorage.setItem(actualKey, value);

            // This should be removed once local storage namespace is fully migrated.
            // Essential account data is stored in local storage. Removing this could be a breaking change if done too soon.
            windowObj.localStorage.removeItem(actualOldKey);
        } catch {
            // suppress quota exception
        }
    } else {
        // window object (or self) doesn't have access to LocalStorage on Web Worker thread
        // So we have to get a mocked version of it from owa-window-data in-memory storage
        const windowData = getWindowData();
        // "windowData?.localStorage?.setItem" on Web Worker thread refers to
        // "setItem" function defined here, but on the main thread.
        // We don't apply "olk-" prefix logic on Web Worker thread as it will be added on the main thread
        windowData?.localStorage?.setItem(key, value);
    }
}

export function getItem(windowObj: Window, key: string): string | null {
    const isEnabled = isBootFeatureEnabled('localStorageOwaPrefix');
    const enabledPrefixKey = isEnabled ? owaPrefix + key : key;
    const disabledPrefixKey = isEnabled ? key : owaPrefix + key;

    if (localStorageExists(windowObj)) {
        try {
            return (
                windowObj.localStorage.getItem(enabledPrefixKey) ||
                windowObj.localStorage.getItem(disabledPrefixKey)
            );
        } catch {
            return null;
        }
    } else {
        // window object (or self) doesn't have access to LocalStorage on Web Worker thread
        // So we have to get a mocked version of it from owa-window-data in-memory storage
        const windowData = getWindowData();
        return (
            windowData?.localStorage?.getItem(enabledPrefixKey) ||
            windowData?.localStorage?.getItem(disabledPrefixKey) ||
            null
        );
    }
}

export function removeItem(windowObj: Window, key: string): void {
    if (localStorageExists(windowObj)) {
        try {
            windowObj.localStorage.getItem(key)
                ? windowObj.localStorage.removeItem(key)
                : windowObj.localStorage.removeItem(owaPrefix + key);
        } catch {
            // suppress exception
        }
    } else {
        // window object (or self) doesn't have access to LocalStorage on Web Worker thread
        // So we have to get a mocked version of it from owa-window-data in-memory storage
        const windowData = getWindowData();
        // "windowData?.localStorage?.removeItem" on Web Worker thread refers to
        // "removeItem" function defined here, but on the main thread.
        return windowData?.localStorage?.removeItem(key);
    }
}

export function getNumberOfItems(windowObj: Window): number {
    if (localStorageExists(windowObj)) {
        try {
            return windowObj.localStorage.length;
        } catch {
            // suppress exception
        }
    }

    return 0;
}

export function getKey(windowObj: Window, index: number): string | null {
    if (localStorageExists(windowObj)) {
        try {
            const regex = RegExp('^' + owaPrefix);
            const key = windowObj.localStorage.key(index);
            if (key) {
                return key?.replace(regex, '');
            }
        } catch {
            // suppress exception
        }
    }

    return null;
}

export function itemExists(windowObj: Window, key: string): boolean {
    return (getItem(windowObj, key) || getItem(windowObj, owaPrefix + key)) !== null;
}

export function localStorageExists(windowObj: Window | WindowData): boolean {
    try {
        return windowObj && !!windowObj.localStorage;
    } catch {
        return false;
    }
}

//If owaPrefix is ever updated, this function can be called to replace the old prefix that was used.
export function updateOldPrefix(windowObj: Window, oldPrefix: string): void {
    if (localStorageExists(windowObj)) {
        const regex = RegExp('^' + oldPrefix);
        for (let i = 0; i < windowObj.localStorage.length; i++) {
            var currentKey = windowObj.localStorage.key(i);
            if (currentKey?.startsWith(oldPrefix)) {
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                 * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                 *	> Forbidden non-null assertion. */
                var value = windowObj.localStorage.getItem(currentKey)!;
                setItem(windowObj, currentKey.replace(regex, ''), value);
                removeItem(windowObj, currentKey);
            }
        }
    }
}

export function getPrefix(): string {
    return owaPrefix;
}
