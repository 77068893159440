import type { MailboxInfo, MailboxRank, MailboxType } from 'owa-client-types';
import { CoprincipalMailboxRank } from 'owa-client-types';
import type { ExternalData } from './ExternalData';
import type { BootState } from './BootState';
import type { BootStateData } from './BootStateData';
import type { PolicyStateTypeEnum } from './PolicyState';
import type {
    AccountSourceType,
    RemovedAccount,
    LicensingMailboxInfo,
} from 'owa-account-source-list-types';

export enum LoadState {
    NotStarted,
    NotSupported,
    Loading,
    Loaded,
    Error,
}

export interface Contracts {
    supportsCalendar: boolean;
    supportsContacts: boolean;
    supportsMail: boolean;
    supportsSettings: boolean;
    isMicrosoft365Hosted: boolean;
}

// Provides information about the state of the account
export interface AccountState {
    // The numeric value from the OWAConnectedAccountState in owa-account-store package, this
    // value will only be present for client-web applications that have not been migrated
    // to the Monarch multiple account infrastructure
    connectedAccountState: number;
}

export enum AccountDataType {
    M365Mailbox,
    PstFile,
}

// The type of Mailbox that is associated with the account source, we need this to be
// able to specifically identify connected accounts.
export enum AccountSourceMailboxType {
    Archive,
    User,
    Connected,
    Group,
    Shared,
    PublicFolder,
    Teams,
    PstFile,
}

export interface AdditionalAccountInfo {
    coprincipalSourceId: string;
    loadState: LoadState;
    sources: AccountSource[];
}

export interface AccountSource {
    sourceId: string;
    sourceType: AccountSourceType;
    dataType: AccountDataType;
    mailboxRank: MailboxRank;
    displayName: string;
    contracts: Contracts;
    externalData: ExternalData;
    accountSourceMailboxType: AccountSourceMailboxType;
    readonly mailboxInfo: MailboxInfo;
}

export interface M365AccountSource extends AccountSource {
    dataType: AccountDataType.M365Mailbox;
}

export interface PstFileAccountSource extends AccountSource {
    dataType: AccountDataType.PstFile;
    accountSourceMailboxType: AccountSourceMailboxType.PstFile;
}

/**
 * All Coprincipal account sources will go through a boot process, the bootState
 * tracks whether or not yet have completed the pre-render steps.
 */
export interface CoprincipalAccountSource extends AccountSource {
    bootState: BootState;
    bootStateData?: BootStateData;
    policyState?: PolicyStateTypeEnum;
    persistenceId: string;
    // True if the mailbox this account source represents is a delegate, shared, room or other
    // mailbox, that is not the user's owned mailbox.
    isAnotherMailbox: boolean;
    aliases: string[];

    // Optionally specifies a licensing mailbox that is associated with the account source, if this
    // is undefined it means that there is not a licensing mailbox associated with the account source
    licensingMailboxInfo?: LicensingMailboxInfo;
}

export interface M365UserMailboxAccountSource extends CoprincipalAccountSource, AccountSource {
    accountSourceMailboxType: AccountSourceMailboxType.User;
    sharedSources: AdditionalAccountInfo;
    groupSources: AdditionalAccountInfo;
    archiveSources: AdditionalAccountInfo; //Online Archive Account Source
    publicFolderSources: AdditionalAccountInfo;
    teamsSources: AdditionalAccountInfo;
}

export interface M365ArchiveMailboxAccountSource extends M365AccountSource {
    accountSourceMailboxType: AccountSourceMailboxType.Archive;
}

export interface M365SharedMailboxAccountSource extends M365AccountSource {
    accountSourceMailboxType: AccountSourceMailboxType.Shared;
    isAutomapped: boolean;
}

export interface M365GroupMailboxAccountSource extends M365AccountSource {
    accountSourceMailboxType: AccountSourceMailboxType.Group;
}

export interface M365PublicFolderMailboxAccountSource extends M365AccountSource {
    accountSourceMailboxType: AccountSourceMailboxType.PublicFolder;
}

export interface M365TeamsMailboxAccountSource extends M365AccountSource {
    accountSourceMailboxType: AccountSourceMailboxType.Teams;
}

export interface M365ConnectedMailboxAccountSource extends CoprincipalAccountSource {
    dataType: AccountDataType.M365Mailbox;
    accountSourceMailboxType: AccountSourceMailboxType.Connected;
    anchorMailbox: string;
    accountState: AccountState;
}

// For performance reasons we want to be able to look up information about accounts based on just
// the sourceId. When added account sources will be places in the sourcesBySourceId map and will use
// the AccountAndCoprincipalSourceId to provide both the AccountSource and the Coprincial sourceId (if
// there is a Coprincipal account).
export interface AccountAndCoprincipalSourceId {
    source: AccountSource;
    coprincipalSourceId?: string;
}

// Defines the data for the account source list that can be passed between
// the main thread and the worker thread. This does not include the Map object
// as that would be removed in marshalling to the worker.
export interface AccountSourceListData {
    globalSettingsSourceId: string;
    sources: CoprincipalAccountSource[];
    removedAccounts: RemovedAccount[];

    // The list of licensing mailboxes that are associated with the application
    licensingIdentities: LicensingMailboxInfo[];
}

export enum IndexerMatchType {
    AccountUserIdentity,
    RemovedUserIdentity,
    AccountAlias,
}

export interface IndexerAndMatchInfo {
    indexer: string;
    accountIndex: number;
    matchType: IndexerMatchType;
}

export interface AccountSourceList extends AccountSourceListData {
    // Index the sources in the account source list by sourceId
    sourcesBySourceId: Map<string, AccountAndCoprincipalSourceId>;
    indexerByUserIdentity: Map<string, IndexerAndMatchInfo>;
}

export const accountSourceDataTypeChecker = {
    isM365Mailbox: (account?: AccountSource): account is M365AccountSource =>
        account?.dataType === AccountDataType.M365Mailbox,
    isM365UserMailbox: (account?: AccountSource): account is M365UserMailboxAccountSource =>
        accountSourceDataTypeChecker.isM365Mailbox(account) &&
        (account as any).accountSourceMailboxType === AccountSourceMailboxType.User,
    isM365ArchiveMailbox: (account?: AccountSource): account is M365ArchiveMailboxAccountSource =>
        accountSourceDataTypeChecker.isM365Mailbox(account) &&
        (account as any).accountSourceMailboxType === AccountSourceMailboxType.Archive,
    isM365ConnectedMailbox: (
        account?: AccountSource
    ): account is M365ConnectedMailboxAccountSource =>
        accountSourceDataTypeChecker.isM365Mailbox(account) &&
        (account as any).accountSourceMailboxType === AccountSourceMailboxType.Connected,
    isM365GroupMailbox: (account?: AccountSource): account is M365GroupMailboxAccountSource =>
        accountSourceDataTypeChecker.isM365Mailbox(account) &&
        (account as any).accountSourceMailboxType === AccountSourceMailboxType.Group,
    isM365SharedMailbox: (account?: AccountSource): account is M365SharedMailboxAccountSource =>
        accountSourceDataTypeChecker.isM365Mailbox(account) &&
        (account as any).accountSourceMailboxType === AccountSourceMailboxType.Shared,
    isM365PublicFolderMailbox: (
        account?: AccountSource
    ): account is M365PublicFolderMailboxAccountSource =>
        accountSourceDataTypeChecker.isM365Mailbox(account) &&
        (account as any).accountSourceMailboxType === AccountSourceMailboxType.PublicFolder,
    isM365TeamsMailbox: (account?: AccountSource): account is M365TeamsMailboxAccountSource =>
        accountSourceDataTypeChecker.isM365Mailbox(account) &&
        (account as any).accountSourceMailboxType === AccountSourceMailboxType.Teams,
    isPstFile: (account?: AccountSource): account is CoprincipalAccountSource =>
        account?.dataType === AccountDataType.PstFile,
};

export const accountRankTypeChecker = {
    isCoprincipal: (account?: AccountSource): account is CoprincipalAccountSource =>
        account?.mailboxRank === CoprincipalMailboxRank,
};

export const m365MailboxTypeToMailboxType = (
    accountSourceMailboxType: AccountSourceMailboxType
): MailboxType => {
    switch (accountSourceMailboxType) {
        case AccountSourceMailboxType.Archive:
            return 'ArchiveMailbox';
        case AccountSourceMailboxType.Connected:
        case AccountSourceMailboxType.User:
            return 'UserMailbox';
        case AccountSourceMailboxType.Group:
            return 'GroupMailbox';
        case AccountSourceMailboxType.PublicFolder:
            return 'PublicMailbox';
        case AccountSourceMailboxType.Shared:
            return 'SharedMailbox';
        case AccountSourceMailboxType.Teams:
            return 'TeamsMailbox';
        case AccountSourceMailboxType.PstFile:
            return 'PstFile';
        default:
            const _neverValue: never = accountSourceMailboxType;
            return _neverValue;
    }
};
