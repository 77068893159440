import { getItem, setItem } from 'owa-local-storage';
import { getWindowData } from 'owa-window-data';

const ClientIdKey = 'OwaClientId';

let clientId: string | null = null;
export default function getClientId() {
    if (!clientId) {
        // try to get the client id from the cookie

        const clientIdStart =
            getWindowData()?.cookie && getWindowData().cookie.split('ClientId=')[1];
        const newClientId = clientIdStart && clientIdStart.split(';')[0];
        if (newClientId) {
            clientId = newClientId;
            /* eslint-disable no-restricted-globals -- We are explicitly checing if "window" exists */
            if (typeof window !== 'undefined') {
                setItem(window, ClientIdKey, newClientId);
            }
            /* eslint-enable no-restricted-globals */
        } else {
            // Try to get it from local storage if we don't have it in the cookie
            clientId = getItem(self, ClientIdKey);
        }
    }
    return clientId;
}
