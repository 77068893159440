import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootLocalDataAccountSupport" */ './index')
);

export const lazyAddLocalDataAdditionalAccountProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyAddLocalDataAdditionalAccount;
    return action.importAndExecute.bind(action);
});

export const lazyDoCleanupForRemovedLocalDataAccountProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyDoCleanupForRemovedLocalDataAccount;
    return action.importAndExecute.bind(action);
});
