import { postSignal } from './ariaUtils';
import type { IExtendedTelemetryItem } from '@microsoft/1ds-core-js';
import type ErrorDiagnostics from './interfaces/ErrorDiagnostics';
import { createBaseEvent } from './createBaseEvent';
import createBootError from './createBootError';
import type { BootError } from 'owa-shared-start-types';
import { postLanguageTimeZone } from './postLanguageTimeZone';
import { getServerErrorDiagnostics } from './getServerErrorDiagnostics';
import { createStatusErrorMessage } from './createStatusErrorMessage';
import { lazyGetAnchorMailboxProxy } from 'owa-msaljs/lib/lazyAppBoot';
import { isMsalFlowEnabled } from 'owa-msaljs/lib/isMsalFlowEnabled';
import { getConfig } from 'owa-service/lib/config';

export async function languagePostOnBootError(
    emailAddress?: string,
    authToken?: string
): Promise<void> {
    try {
        if (!emailAddress && isMsalFlowEnabled()) {
            emailAddress = await lazyGetAnchorMailboxProxy.importAndExecute();
        } else if (!emailAddress) {
            // Attempt to get the SMTP address from the service config callbacks
            const config = getConfig();
            if (config.getGlobalSettingsAccountMailboxInfo) {
                emailAddress = config.getGlobalSettingsAccountMailboxInfo()?.mailboxSmtpAddress;
            }
        }

        const response = await postLanguageTimeZone(emailAddress, authToken);
        const owaError = response.headers.get('X-OWA-Error');

        if (owaError) {
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            throw new Error(owaError);
        } else if (response.status >= 400) {
            throw createStatusErrorMessage(response);
        }

        // Log success event to Aria
        await postSignal(createLangPostEvent('langpost_success'));
    } catch (e) {
        // Log failed event to Aria
        const langPostError: BootError = createBootError(
            e,
            'LangPost',
            window.location.pathname,
            e.status
        );
        await postSignal(
            createLangPostEvent('langpost_error', getServerErrorDiagnostics(langPostError))
        );
    }
}

function createLangPostEvent(
    eventName: string,
    errorDiagnostics?: ErrorDiagnostics
): Promise<IExtendedTelemetryItem[]> {
    return Promise.resolve([createBaseEvent(eventName, errorDiagnostics)]);
}
