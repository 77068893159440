import assert from 'owa-assert/lib/assert';
import getAccountSourceListStore from '../store/accountSourceListStore';
import { accountRankTypeChecker } from '../store/schema/AccountSourceList';
import { BootState } from '../store/schema/BootState';
import type { MailboxInfo } from 'owa-client-types';
import type { AccountSource, CoprincipalAccountSource } from '../store/schema/AccountSourceList';

/**
 * When getting coprincipal accounts, this funciton is used to indicate which startup state
 * coprincipal accounts should be returned for.
 */
export enum StartupFilter {
    /**
     * Only return coprincipal accounts that are currently starting.
     */
    Starting,

    /**
     * Only return coprinicpal accounts for which Startup has completed.
     */
    Completed,

    /**
     * Only return coprincipal accounts for which startup encountered an error.
     */
    Error,

    /**
     * Return coprincipal accounts that are Starting, Completed, or Error (basically
     * this returns all the coprincipal accounts.
     */
    StartingOrCompleteOrError,
}

/**
 * When getting coprincipal we support filtering based on the contracts that are supported by the account
 */
export enum ContractsSupportedFilter {
    /**
     * Returns all coprincipal accounts regardless of the contracts supported
     */
    Any,

    /**
     * Returns only coprincipal accounts that support the Calendar
     */
    Calendar,

    /**
     * Returns only coprincipal accounts that support Contacts
     */
    Contacts,

    /**
     * Returns only coprincipal accounts that support Mail
     */
    Mail,

    /**
     * Returns only coprincipal accounts that support Settings
     */
    Settings,
}

// Define the type used for filtering coprincipal accounts
type CoprincipalAccountFilterPredicate = (account: CoprincipalAccountSource) => boolean;

type AccountSourceFilterPredicate = (account: AccountSource) => boolean;

/**
 * The predicates for filtering based on the requested StartupState
 */
const filterStartupPredicates: Record<StartupFilter, CoprincipalAccountFilterPredicate> = {
    [StartupFilter.Starting]: (account: CoprincipalAccountSource) =>
        account.bootState !== BootState.StartupComplete &&
        account.bootState !== BootState.StartupError,
    [StartupFilter.Completed]: (account: CoprincipalAccountSource) =>
        account.bootState === BootState.StartupComplete,
    [StartupFilter.Error]: (account: CoprincipalAccountSource) =>
        account.bootState === BootState.StartupError,
    [StartupFilter.StartingOrCompleteOrError]: (_account: CoprincipalAccountSource) => true,
};

// Predicate for filtering the accounts based on the supported contracts
export const filterContractsSupportedPredicates: Record<
    ContractsSupportedFilter,
    AccountSourceFilterPredicate
> = {
    [ContractsSupportedFilter.Any]: (_account: AccountSource) => true,
    [ContractsSupportedFilter.Calendar]: (account: AccountSource) =>
        account.contracts.supportsCalendar,
    [ContractsSupportedFilter.Contacts]: (account: AccountSource) =>
        account.contracts.supportsContacts,
    [ContractsSupportedFilter.Mail]: (account: AccountSource) => account.contracts.supportsMail,
    [ContractsSupportedFilter.Settings]: (account: AccountSource) =>
        account.contracts.supportsSettings,
};

/**
 * Returns the list of Coprincipal accounts
 * @param startup Filter for the startup states for the accounts to be returned
 * @param contracts Filter for the contracts supported by the accounts to be returned
 * @returns The list of Coprincipal accounts that match the requested filters
 */
export default function getCoprincipalAccounts(
    startup: StartupFilter = StartupFilter.StartingOrCompleteOrError,
    contracts: ContractsSupportedFilter = ContractsSupportedFilter.Any
): CoprincipalAccountSource[] {
    const startupPredicate: CoprincipalAccountFilterPredicate = filterStartupPredicates[startup];
    const contractPredicate: AccountSourceFilterPredicate =
        filterContractsSupportedPredicates[contracts];

    return getAccountSourceListStore()
        .sources.map(source => {
            assert(
                accountRankTypeChecker.isCoprincipal(source),
                'All sources must be Coprincipals'
            );
            return source as CoprincipalAccountSource;
        })
        .filter(account => {
            return startupPredicate(account) && contractPredicate(account);
        });
}

/**
 * Returns the list of MailboxInfo associated with the Coprincipal accounts
 * @param startup Filter for the startup states for the accounts to be returned
 * @param contracts Filter for the contracts supported by the accounts to be returned
 * @returns The list of MailboxInfo for Coprincipal accounts that match the requested filters
 */
export function getCoprincipalAccountMailboxInfos(
    startup: StartupFilter = StartupFilter.StartingOrCompleteOrError,
    contracts: ContractsSupportedFilter = ContractsSupportedFilter.Any
): MailboxInfo[] {
    return getCoprincipalAccounts(startup, contracts).map(account => account.mailboxInfo);
}
