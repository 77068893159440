import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootNativePostBoot" */ './lazyFunctions')
);

export const lazySetupNativeHostPostBoot = new LazyAction(lazyModule, m => {
    const action = m.lazySetupNativeHostPostBoot;
    return action.importAndExecute.bind(action);
});
