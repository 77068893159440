import { getQueryStringParameters } from 'owa-querystring';
import { getSessionId } from 'owa-config';
import getRootVdirName from 'owa-url/lib/getRootVdirName';
import getScopedPath from 'owa-url/lib/getScopedPath';
import getUrlWithAddedQueryParameters from 'owa-url/lib/getUrlWithAddedQueryParameters';
import { type OwaWebServerAuthState } from 'owa-service/lib/types/OwaWebServerAuthState';
import getPathForAuthRedirect from 'owa-url/lib/getFullPathForAuthRedirect';

const STATE_QUERY_PARAM_KEY = 'state';
const CLAIMS_CHALLENGE_HEADER = 'X-OWA-ClaimChallenge';
const CLAIMS_CHALLENGE_KEY = 'cc';
export const AUTH_REDIRECT_KEY = 'authRedirect';
export const MSAL_AUTH_REDIRECT_KEY = 'msalAuthRedirect';

export function redirect(
    location: Location,
    queryStringKey: string,
    value: string,
    addSessionId?: boolean,
    shouldAddStateParam?: boolean,
    headers?: Headers
): boolean {
    // if we didn't find it, then let's add it
    const params = getQueryStringParameters(location);
    let shouldRedirect = false;
    const doesNotHaveQueryStringKey = params[queryStringKey] !== value;

    if (doesNotHaveQueryStringKey) {
        // Add cc query param if we get back a ClaimChallenge on any service
        // call failure so as to re-initiate a login flow and resolve CAE error
        if (queryStringKey == AUTH_REDIRECT_KEY) {
            const claimsChallengeValue = headers ? headers.get(CLAIMS_CHALLENGE_HEADER) : undefined;
            if (claimsChallengeValue && params.cc !== claimsChallengeValue) {
                params[CLAIMS_CHALLENGE_KEY] = decodeURIComponent(claimsChallengeValue as string);
            }
        }

        if (queryStringKey == MSAL_AUTH_REDIRECT_KEY) {
            // skip Service Worker cache on first MSAL auth error
            params['bO'] = '4';
        }

        params[queryStringKey] = value;
        shouldRedirect = true;
    }

    if (shouldRedirect) {
        if (addSessionId) {
            params['sessionId'] = getSessionId();
        }

        const vdir = getRootVdirName() || 'mail';
        let rootPath = `${getScopedPath(`/${vdir}`)}/`;

        if (shouldAddStateParam) {
            params[STATE_QUERY_PARAM_KEY] = '0';
            rootPath = `${getPathForAuthRedirect(`/${vdir}`)}`;
        }

        location.assign(getUrlWithAddedQueryParameters(rootPath, params));
    }

    return shouldRedirect;
}

export function redirectTo(url: string): true {
    self.location.assign(url);
    return true;
}
