import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootFreezeDry" */ './lazyFunctions')
);

export const lazyKickOffFreezeDryProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyKickOffFreezeDry;
    return action.importAndExecute.bind(action);
});

export const lazySetShouldInitializeFreezeDryProxy = new LazyAction(lazyModule, m => {
    const action = m.lazySetShouldInitializeFreezeDry;
    return action.importAndExecute.bind(action);
});
