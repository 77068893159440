import type { ExtendedWorkerOptions } from 'owa-analytics-types';
import { getScriptPath, getScriptBackupPath } from 'owa-config';
import { workerTemplate } from './utils/workerTemplate';
import { postMessageWrapper } from 'owa-worker-wrapper';
import { isBootFeatureEnabled } from 'owa-metatags/lib/isBootFeatureEnabled';

// Analytics' `initializeInWebWorker` relies on runtime failure to fallback to main thread
// Safari doesn't have Worker constructor in WorkerGlobalScope, so it will throw on import if you extend Worker

// Adapted from https://github.com/webpack/webpack/discussions/14648#discussioncomment-1589272
// until webpack has native support for cross-origin workers.
class OwaWorker {
    constructor(scriptName: string, options?: ExtendedWorkerOptions) {
        const scriptPath = getScriptPath();
        const backupScriptPath = getScriptBackupPath();
        const workerAsyncLoad = isBootFeatureEnabled('workerAsyncLoad');
        let workerScriptPolicy: Pick<TrustedTypePolicy, 'createScriptURL'> | undefined;
        if (window.trustedTypes) {
            workerScriptPolicy = window.trustedTypes.createPolicy('workerScriptPolicy', {
                createScriptURL: (input: string) => input,
            });
        }

        // Use provided calendarWorkerURL if available, otherwise create a new object URL
        const objectURL = options?.calendarWorkerURL
            ? options.calendarWorkerURL
            : URL.createObjectURL(
                  new Blob(
                      [
                          `(${workerTemplate.toString()})("${scriptName}","${scriptPath}","${backupScriptPath}", ${workerAsyncLoad});`,
                      ],
                      {
                          type: 'application/javascript',
                      }
                  )
              );

        let worker;
        if (options?.workerName === 'CalendarWorker') {
            // TODO: Use DataURL if popout window and shared window share the same bundles.
            worker = new SharedWorker(
                (workerScriptPolicy?.createScriptURL(objectURL) as unknown as string | undefined) ??
                    objectURL,
                {
                    name: scriptName,
                    ...options,
                }
            );
            // Store the new URL in localStorage for future instances
            if (!options?.calendarWorkerURL) {
                localStorage.setItem('calendarWorkerURL', objectURL);
            }
        } else {
            worker = new Worker(
                // typescript doesn't know about trusted types :( so we need to cast it
                (workerScriptPolicy?.createScriptURL(objectURL) as unknown as string | undefined) ??
                    objectURL,
                {
                    name: scriptName,
                    ...options,
                }
            );
        }
        URL.revokeObjectURL(objectURL);

        // Wrap the worker to add analytics
        if (options && options.workerName !== 'CalendarWorker') {
            wrapWorkerForAnalytics(worker, options);
        }

        return worker;
    }
}

function wrapWorkerForAnalytics(worker: OwaWorker, workerOptions: ExtendedWorkerOptions) {
    const workerAsWorker = worker as Worker;
    const pPostMessage = workerAsWorker.postMessage;

    workerAsWorker.postMessage = function (
        message: any,
        options?: Transferable[] | StructuredSerializeOptions
    ) {
        postMessageWrapper(
            workerAsWorker,
            pPostMessage,
            message,
            options,
            workerOptions.workerName
        );
    };
}

// Fix for proper typechecking
export default OwaWorker;
