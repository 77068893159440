import { getOrigin } from 'owa-url/lib/getOrigin';
import { isBootFeatureEnabled } from 'owa-metatags/lib/isBootFeatureEnabled';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import findMetatag from 'owa-metatags/lib/findMetatag';
import {
    MSAL_ENTERPRISE_PUBLIC_ORIGINS,
    PLACES_MSAL_ENTERPRISE_PUBLIC_ORIGINS,
} from './utils/constants';
import { getApp, isRunningInMetaOSHub } from 'owa-config';
import { isMonitoringProbe } from './isMonitoringProbe';

/**
 * Checks if the request is enabled for business accounts
 **/
export function isMsalEnabledForBusiness(): boolean {
    if (isMonitoringProbe() && isBusinessDomain()) {
        // MSEXCHMON monitoring probe should return true to ensure anchor mailbox is set
        return true;
    }
    return (
        areMsalBusinessFlightsEnabledForCurrentApp() &&
        isHostAppFeatureEnabled('msalTokenFetch') &&
        isBusinessDomain()
    );
}

/**
 * Checks if this is a business domain
 **/
export function isBusinessDomain(): boolean {
    // get the origin of the request with trailing slash to compare against the OWA_RESOURCE_URL_ENTERPRISE urls.
    const origin = getOrigin();

    // If getOrigin is one of OWA public urls, then return true.
    if (MSAL_ENTERPRISE_PUBLIC_ORIGINS.some(re => re.test(origin))) {
        return true;
    }

    // For business accounts we have different vanity urls and soverign cloud urls.
    const businessCanonicalHostName = findMetatag('businessCanonicalHostName');
    const originUrl = new URL(origin);

    // If host name is equal to businessCanonicalHostName(vanity urls and sovereign cloud urls), then return true.
    return (
        businessCanonicalHostName != undefined && businessCanonicalHostName == originUrl.hostname
    );
}

function areMsalBusinessFlightsEnabledForCurrentApp(): boolean {
    const app = getApp();

    switch (app) {
        case 'AppHost':
        case 'BackgroundSync':
        case 'Calendar':
        case 'CalendarDeepLink':
        case 'FilesHub':
        case 'Groups':
        case 'Mail':
        case 'MailDeepLink':
        case 'Oobe':
        case 'People':
        case 'PublishedCalendar':
        case 'Tasks':
            return isRunningInMetaOSHub()
                ? isBootFeatureEnabled('auth-msaljs-hosted')
                : isBootFeatureEnabled('auth-msaljs-business');
        case 'CalendarDeepLinkOpx':
        case 'MailDeepLinkOpx':
        case 'Opx':
            return isBootFeatureEnabled('auth-msaljs-opx');
        case 'Addison':
            return isBootFeatureEnabled('auth-msaljs-newsletters');
        case 'Eventify':
            return isBootFeatureEnabled('auth-msaljs-eventify');
        case 'MicrosoftPlaces':
        case 'Places':
            return isBootFeatureEnabled('auth-msaljs-places') || isPlacesCloudDomain();
        case 'Bookings':
        case 'BookingsC2':
        case 'BookingsMobile':
        case 'BookWithMe':
            return isBootFeatureEnabled('auth-msaljs-bookings');
        case 'FindTime':
            return isBootFeatureEnabled('auth-msaljs-findtime');
        case 'Meet':
            return isBootFeatureEnabled('auth-msaljs-meet');
        case 'CopilotHub':
        case 'MiniMail':
        case undefined:
            // if you're reading this, you probably added a new app and wondered why the build broke. This
            // function controls OWA's transition to MSAL-based auth on a per-app level. If your app is meant
            // to be an independent experience and is not a core OWA module/scenario, you may need your own
            // separate clientId. Otherwise, add your app to the first `auth-msaljs-business` case. You
            // should not add yourself here to the `false` case. Contact owaauth with any questions.
            return false;
        default:
            return app satisfies never;
    }
}

// This is a temporary solution until Places rolls out to App ID and MSAL to WW.
// Tracking at: ADO 292898
function isPlacesCloudDomain() {
    const origin = getOrigin();
    return PLACES_MSAL_ENTERPRISE_PUBLIC_ORIGINS.some(re => re.test(origin));
}
