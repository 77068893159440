import type { MailboxInfo, MailboxType } from 'owa-client-types';

const allowedLicensingActions = new Set<string>([]);

/**
 * Checks the action against the allowed set of actions for licensing calls. And returns true
 */
export function isAllowedLicensingCall(action?: string): boolean {
    return !!action && allowedLicensingActions.has(action);
}

const LicensingMailboxType: MailboxType = 'UserPrincipalName';

/**
 * MailboxInfo's that are used for licensing may only have a user identity and not
 * an actual Exchange mailbox that can be accessed. Because of this we have an
 * allowed set of actions that can be used when there is not a mailbox. If the
 * MailboxInfo is a licensing identity, and the action is not supported the caller
 * will raise an alert and throw an error.
 * @param mailboxInfo Optionally specfiy the mailboxInfo to check
 * @param action
 * @returns
 */
export function isDisallowedLicensingCall(mailboxInfo?: MailboxInfo, action?: string): boolean {
    return mailboxInfo?.type == LicensingMailboxType && !isAllowedLicensingCall(action);
}
