import type Getter from './types/Getter';
import type { LazyModuleType } from './types/LazyModuleType';
import type LazyModule from './LazyModule';
import type { TraceBundlingSource } from './types/TraceBundlingSource';
import createQuickPromise from './utils/createQuickPromise';
import { getConfig } from './utils/config';
import type { CustomCheckpointCode, PerformanceDatapointType } from 'owa-analytics-types';

const FailureDatapointName = 'LazyImportFailure';

const IMPORT_ID_REGEX = /function\s*\(.*\)\s*{\s*return [^.]*([^;]*.*)}/;

export default class LazyImport<TImport, TLazyModule extends LazyModule<any>> {
    private importPromise: Promise<TImport> | undefined;
    private pendingImports = 0;

    constructor(
        private lazyModule: TLazyModule,
        private getter: Getter<TImport, LazyModuleType<TLazyModule>>,
        private shouldGovern?: boolean
    ) {}

    import = (
        traceSource?: TraceBundlingSource,
        perfDatapoint?: PerformanceDatapointType | CustomCheckpointCode
    ): Promise<TImport> => {
        if (!this.importPromise) {
            let moduleImportPromise;
            try {
                moduleImportPromise = this.lazyModule.importModule(
                    this.shouldGovern ? 'LazyGovernImport' : 'LazyImport',
                    traceSource || 'LazyImport',
                    this.getName(),
                    perfDatapoint
                );
                this.importPromise = moduleImportPromise
                    .then(module => {
                        // Get the value out of the module
                        const importValue = this.getter(module);

                        // Once the import is loaded, we return a synchronous promise for faster access
                        this.importPromise = createQuickPromise(importValue);

                        this.pendingImports = 0;
                        return importValue;
                    })
                    .catch(error => {
                        // Reset the promise so we'll try to load it again
                        this.importPromise = undefined;

                        // Log some info about the failure
                        getConfig().logUsage(FailureDatapointName, {
                            message: error.message,
                            pendingImports: this.pendingImports,
                        });

                        this.pendingImports = 0;
                        throw error;
                    });
            } catch (error) {
                // Handle errors from calling then() on the promise (bug #76920)
                this.importPromise = Promise.reject(error);
            }
        }

        this.pendingImports++;
        return <Promise<TImport>>this.importPromise;
    };

    // Don't use this.  Pretend you didn't even see it.
    dangerouslyImportSync() {
        const moduleValue = this.lazyModule.getModuleValue();
        if (!moduleValue) {
            throw new Error('Module or import is not available yet.');
        }

        return this.getter(moduleValue);
    }

    tryImportForRender(): TImport | undefined {
        const moduleValue = this.lazyModule.getModuleValue();
        // Check if we already have the value.  It's important that we check
        // the loaded state via the store -- that way, if this method is called
        // during render(), the component will get rerendered once the import
        // is loaded.
        if (moduleValue) {
            return this.getter(moduleValue);
        }
        this.import();
        return undefined;
    }

    isLoaded(): boolean {
        // Check whether we have ever loaded this import during this session.
        return this.lazyModule.observableGetIsLoaded();
    }

    /**
     * Tries to get a human-readable name for this import
     */
    getName(): string {
        // Names are not mangled across lazy imports, so we can do this hack to try to find the property access
        const getterString = this.getter.toString();
        try {
            const match = getterString.match(IMPORT_ID_REGEX);
            return match && match.length > 1 ? match[1] : getterString;
        } catch {
            return getterString;
        }
    }
}
