import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootAccountSourceList" */ './lazyFunctions')
);

export const lazyUpdateAccountStateBasedOnPolicyProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyUpdateAccountStateBasedOnPolicy;
    return action.importAndExecute.bind(action);
});

export const lazyEnsurePreRenderForAccountProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyEnsurePreRenderForAccount;
    return action.importAndExecute.bind(action);
});
export const lazyAreNewAccountAndExistingAccountsMutuallyCompliantProxy = new LazyAction(
    lazyModule,
    m => {
        const action = m.lazyAreNewAccountAndExistingAccountsMutuallyCompliant;
        return action.importAndExecute.bind(action);
    }
);
export const lazyOobeInitForNewlyAddedGlobalSettingsAccountProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyOobeInitForNewlyAddedGlobalSettingsAccount;
    return action.importAndExecute.bind(action);
});
export const lazyIsLicenseRequirementsCompliantProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyIsLicenseRequirementsCompliant;
    return action.importAndExecute.bind(action);
});
export const lazyAccountSourceListPostRenderProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyAccountSourceListPostRender;
    return action.importAndExecute.bind(action);
});
