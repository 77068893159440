import { logGreyErrorFromAccounts, logUsageFromAccounts } from 'owa-account-analytics';
import { StepsCustomData } from 'owa-analytics-record-steps';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import getCoprincipalAccounts from '../selectors/getCoprincipalAccounts';
import type { CoprincipalAccountSource } from '../store/schema/AccountSourceList';

// Value used when the account does not match the sourceId
const doesNotMatchSourceId = -1;

/**
 * Returns an array of the indexes that match the supplied sourceId
 * @param sourceId SourceId to be matched to accounts
 * @returns Comma seperate list of the matching account indexes
 */
function accountsMatchingSourceId(sourceId: string): string {
    return getCoprincipalAccounts()
        .map((account, index) => (account.sourceId === sourceId ? index : doesNotMatchSourceId))
        .filter(index => index !== doesNotMatchSourceId)
        .map(index => index.toString())
        .join(',');
}

/**
 * Adds additional diagnostic data for the match
 * @param match The matching coprincipal account for which data is to be logged
 * @param first The first coprincipal account that matched the indexer value
 * @param index Index of the entry in the list of matches
 * @param diagnosticData Diagnostic data to which data for the specified matching coprincipal account
 */
function addDiagnosticDataForMatch(
    match: CoprincipalAccountSource,
    matches: CoprincipalAccountSource[],
    steps: StepsCustomData
) {
    const type = match.sourceType.toString();
    const smtpLen = match.mailboxInfo.mailboxSmtpAddress.length;
    const userIdLen = match.mailboxInfo.userIdentity.length;
    const sameSmtp = matches
        .map(item =>
            item.mailboxInfo.mailboxSmtpAddress == match.mailboxInfo.mailboxSmtpAddress ? 'Y' : 'N'
        )
        .join(',');
    const sameUserId = matches
        .map(item => (item.mailboxInfo.userIdentity == match.mailboxInfo.userIdentity ? 'Y' : 'N'))
        .join(',');
    const sameType = matches
        .map(item => (item.sourceType == match.sourceType ? 'Y' : 'N'))
        .join(',');
    const sourceIdMatches = accountsMatchingSourceId(match.sourceId);

    steps.addStep('match', {
        type,
        smtpLen,
        userIdLen,
        sameSmtp,
        sameUserId,
        sameType,
        sourceIdMatches,
    });
}

/**
 * Logs information about the duplicate matches that were found for the indexer value
 * and returns an error that with this diagnostic data
 * @param indexerValue The indexer value that was matched
 * @param matches Array of matches for an indexer value
 */
function logDuplicateIndexerFound(indexerValue: string, matches: CoprincipalAccountSource[]) {
    const steps = new StepsCustomData();
    steps.addStep('matches', { cnt: matches.length, indexerLen: indexerValue.length });

    matches.forEach((match: CoprincipalAccountSource) => {
        addDiagnosticDataForMatch(match, matches, steps);
    });

    const err = new Error('DuplicateCoprincipalIndexerFound');
    logGreyErrorFromAccounts(
        'DuplicateCoprincipalIndexerFound',
        err,
        steps.getCustomDataMapForSteps()
    );
    return err;
}

const loggedIndexerValues = new Set<string>();

function logAccountNotFoundForIndexer(indexerValue: string) {
    // We only want to log once per account per session
    if (!loggedIndexerValues.has(indexerValue)) {
        loggedIndexerValues.add(indexerValue);

        const err = new Error('AccountNotFoundForIndexer');
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logUsageFromAccounts(err.message, { stack: err.stack });
    }
}

/**
 * Returns the CoprincipalAccountSource associated with the specified indexer value
 * @param indexerValue Value obtained via a getIndexerValueForMailboxInfo call
 * @returns CoprincipalAccountSource if found or undefined if not found
 */
export default function getCoprincipalAccountForIndexerValue(
    indexerValue: string,
    logIfNotFound: boolean = false
): CoprincipalAccountSource | undefined {
    const matches = getCoprincipalAccounts().filter(
        account => getIndexerValueForMailboxInfo(account.mailboxInfo) == indexerValue
    );

    if (logIfNotFound && matches.length === 0) {
        // We expected that we should find the account for an indexer value but did not
        logAccountNotFoundForIndexer(indexerValue);
    } else if (matches.length > 1) {
        // We should not find more than one indexer value. Log the error details and
        // the raise an alert but do not block the application from continuing
        logDuplicateIndexerFound(indexerValue, matches);
    }

    return matches[0];
}
