import getAccountSourceListStore from '../store/accountSourceListStore';
import type { AccountAndCoprincipalSourceId } from '../store/schema/AccountSourceList';
import { SourceIdPartDelimiter } from 'owa-client-types/lib/SourceIdPartDelimiter';
import { resourceTypeLicense } from 'owa-account-source-list-types/lib/utils/sourceIdResourceTypes';

/**
 * Returns the AccountSource and coprincipal sourceId associated with the specified sourceId
 * @param sourceId The sourceId to be located
 * @returns Information about the account and coprincipal sourceId or undefined if the sourceId is
 *          not found in the store (expected when the source has been removed)
 */
export default function getAccountAndCoprincipalSourceId(
    sourceId: string
): AccountAndCoprincipalSourceId | undefined {
    const parts = sourceId.split(SourceIdPartDelimiter);
    if (parts.length > 1 && parts[1] === resourceTypeLicense) {
        // licenses are not stored in the accountSourceListStore, and return the coprincipal account
        return getAccountAndCoprincipalSourceId(parts[0]);
    }

    return getAccountSourceListStore().sourcesBySourceId.get(sourceId);
}
